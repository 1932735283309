import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApplicationResponse } from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import { OrderStore } from "@src/views/orders/domain/orders.model";

export const fetchDashboardChartData = createAsyncThunk(
  "dashboard/fetchChartData",
  async function () {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/chart/`
      );
      return response.data.data || [];
    } catch (err) {
      return [];
    }
  }
);

const dashboardSlice = createSlice({
  name: "orders",
  initialState: {
    minimal: { status: "loading", error: null, data: [] },
  } as OrderStore,
  reducers: {
    logger: (state, action) => console.log(action.payload),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardChartData.rejected, (state) => {
      state.minimal.status = "failed";
      state.minimal.data = [];
    });
    builder.addCase(fetchDashboardChartData.fulfilled, (state, action) => {
      state.minimal.status = "idle";
      state.minimal.data = action.payload;
    });
    builder.addCase(fetchDashboardChartData.pending, (state) => {
      state.minimal.status = "loading";
      state.minimal.data = [];
    });
  },
});

export default dashboardSlice.reducer;
