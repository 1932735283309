import { createAsyncThunk, createSlice, Action } from "@reduxjs/toolkit";
import { ApplicationResponse } from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import { showFailedToast } from "@src/utils/toast.utils";
import { OrderStore } from "@views/orders/domain/orders.model";
import { toast } from "react-toastify";

export const fetchOrdersList = createAsyncThunk<any[], void>(
  "orders/fetchOrders",
  async () => {
    return [
      {
        invoiceNo: "INV-1",
        type: "Picking",
        source: "Location 1",
        destination: "Location 2",
        createdOn: "01 Jan 2023",
      },
      {
        invoiceNo: "INV-2",
        type: "Putting",
        source: "Location 1",
        destination: "Location 2",
        createdOn: "01 Jan 2023",
      },
    ];
  }
);

export const updateInwardOrder = createAsyncThunk(
  "orders/updateInwardOrders",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await api.put<any>(
        `${AppConstants.api}/orders/${data.orderId}/process`,
        data.data
      );
      if (response?.data?.status === "ERROR") {
        // Handle error response
        showFailedToast(`Error: ${response?.data?.error?.attributes?.payload}`);
        return rejectWithValue(response.data);
      }
      return response.data.data || [];
    } catch {
      return [];
    }
  }
);

export const fetchReturnOrdersList = createAsyncThunk(
  "orders/fetchReturningOrders",
  async (page: any) => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/orders/list?type=RETURN&offset=${page.page}&pageSize=5&search=${page.query}`
      );
      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

export const fetchOrderInfo = createAsyncThunk(
  "order/fetchOrderInfo",
  async (data: any) => {
    try {
      const response = await api.get<any>(
        `${AppConstants.api}/orders/info?id=${data.orderId}`
      );
      return response.data.data || [];
    } catch {
      return [];
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    minimal: {
      status: "loading",
      error: null,
      data: [
        {
          info: [],
          returnList: [],
        },
      ],
    },
  } as OrderStore,
  reducers: {
    updateOrdersList: (state, action) => {
      state.minimal.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(fetchOrdersList.pending, (state, action) => {
    //     state.minimal = {
    //       status: "loading",
    //       data: [],
    //       error: null,
    //     };
    //   })
    //   .addCase(fetchOrdersList.fulfilled, (state, action) => {
    //     state.minimal = {
    //       status: "idle",
    //       data: action.payload,
    //       error: null,
    //     };
    //   })
    //   .addCase(fetchOrdersList.rejected, (state, action) => {
    //     state.minimal = {
    //       status: "failed",
    //       data: [],
    //       error: action.error,
    //     };
    //   });
    builder
      .addCase(updateInwardOrder.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(updateInwardOrder.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: state.minimal.data,
          error: null,
        };
      })
      .addCase(updateInwardOrder.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
        showFailedToast("Order couldn't be updated!");
      });
    builder
      .addCase(fetchOrderInfo.pending, (state) => {
        state.minimal.status = "loading";
        state.minimal.data[0].info = [];
        state.minimal.error = null;
      })
      .addCase(fetchOrderInfo.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.data[0].info = action.payload;
        state.minimal.error = null;
      })
      .addCase(fetchOrderInfo.rejected, (state, action) => {
        state.minimal.status = "failed";
        state.minimal.data[0].info = [];
        state.minimal.error = action.error;
      });

    builder
      .addCase(fetchReturnOrdersList.pending, (state) => {
        state.minimal.status = "loading";
        state.minimal.data[0].returnList = [];
        state.minimal.error = null;
      })
      .addCase(fetchReturnOrdersList.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.data[0].returnList = action.payload;
        state.minimal.error = null;
      })
      .addCase(fetchReturnOrdersList.rejected, (state, action) => {
        state.minimal.status = "failed";
        state.minimal.data[0].returnList = [];
        state.minimal.error = action.error;
      });
  },
});

export const { updateOrdersList } = ordersSlice.actions;
export default ordersSlice.reducer;
