import { updateItemsList } from "@src/redux/slices/items.slice";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ApplicationResponse,
  StateTrackedData,
} from "@common/domain/common.model";
import {
  LocationStore,
  NewLocationModelRef,
} from "@views/locations/domain/locations.model";
import AppConstants from "@src/utils/app.constants";
import convertToForm from "@src/utils/payload-to-form-converter";
import api from "@src/common/services/api.service";

export const fetchPuttingLocationsList = createAsyncThunk(
  "locations/fetchPuttingList",
  async (data: any) => {
    const response = await api.get<ApplicationResponse<any[]>>(
      `${AppConstants.api}/location/receive?itemId=${data}`
    );
    return response.data.data || [];
    // return [
    //   {
    //     id: "someid123",
    //     name: "A-01",
    //     type: "Rack",
    //     created_On: "2015-",
    //     location_capacity: "50",
    //     location_used_space: "40",
    //     units: "some units",
    //   },
    //   {
    //     id: "so231123",
    //     name: "B-01",
    //     type: "Rack",
    //     created_On: "2015-",
    //     location_capacity: "100",
    //     location_used_space: "50",
    //     units: "some units",
    //   },
    //   {
    //     id: "2ww2e12123",
    //     name: "C-03",
    //     type: "Rack",
    //     created_On: "2015-",
    //     location_capacity: "60",
    //     location_used_space: "20",
    //     units: "some units",
    //   },
    // ];
  }
);

export const fetchPickingLocationsList = createAsyncThunk(
  "locations/fetchPickingList",
  async (data: any) => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/location/dispatch?${data}`
      );
      return response.data.data || [];
    } catch (err) {
      return [];
    }
    // return [
    //   {
    //     id: "someid123",
    //     name: "A-01",
    //     type: "Rack",
    //     created_On: "2015-",
    //     location_capacity: "50",
    //     location_used_space: "40",
    //     units: "some units",
    //   },
    //   {
    //     id: "so231123",
    //     name: "B-01",
    //     type: "Rack",
    //     created_On: "2015-",
    //     location_capacity: "100",
    //     location_used_space: "50",
    //     units: "some units",
    //   },
    //   {
    //     id: "2ww2e12123",
    //     name: "C-03",
    //     type: "Rack",
    //     created_On: "2015-",
    //     location_capacity: "60",
    //     location_used_space: "20",
    //     units: "some units",
    //   },
    // ];
  }
);

export const fetchLocationsList = createAsyncThunk(
  "locations/fetchLocations",
  async (type: string) => {
    // const response = await api.get<ApplicationResponse<any[]>>(`${AppConstants.api}/locations`)

    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/location/list?type=${type}`
      );
      return response.data.data || [];
    } catch (error) {
      return [];
    }

    // return [
    //   {
    //     name: "A-01",
    //     type: "RACK",
    //     parentLocation: "Location 1",
    //     capacity: "10 / 250",
    //     createdOn: "01 Jan 2023",
    //   },
    //   {
    //     name: "Location 1",
    //     type: "AREA",
    //     parentLocation: "--",
    //     capacity: "10 / 1000",
    //     createdOn: "01 Jan 2023",
    //   },
    // ];
  }
);
export const fetchBatchNumber = createAsyncThunk(
  "location/fetchBatchNumbers",
  async (locationId: string) => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/location/batch?locationId=${locationId}`
      );
      return response.data.data || [];
    } catch (err) {
      return [];
    }
  }
);
export const fetchTraceabilityNumber = createAsyncThunk(
  "location/fetchTraceabilityNumber",
  async (locationBatchInfo: any) => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/location/trace?id=${locationBatchInfo.locationId}&batch=${locationBatchInfo.batchNo}`
      );
      return response.data.data || [];
    } catch (err) {
      return [];
    }
  }
);

export const uploadLocations = createAsyncThunk<
  void,
  {
    payload: NewLocationModelRef;
  }
>("locations/upload", async (wrapper, thunkAPI) => {
  try {
    const url = `${AppConstants.api}/users/new`;
    const { payload } = wrapper;

    const keys = Object.keys(payload) as Array<keyof NewLocationModelRef>;
    const formData = convertToForm(payload, keys);

    const response = await api.post<ApplicationResponse<void>>(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      return thunkAPI.rejectWithValue(err.response.data);
    }

    return thunkAPI.rejectWithValue({
      error: {
        message: "Network error",
        code: "NETWORK_ERROR",
        attributes: {},
      },
      status: "error",
      timestamp: new Date().toISOString(),
    });
  }
});

export const createLocation = createAsyncThunk(
  "locations/create",
  async (data: any) => {
    try {
      const response = await api.post<ApplicationResponse<any[]>>(
        `${AppConstants.api}/location/create`,
        data
      );
      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

export const deleteLocation = createAsyncThunk(
  "location/delete",
  async (id: string) => {
    try {
      const response = await api.delete<ApplicationResponse<any[]>>(
        `${AppConstants.api}/location/delete?locationId=${id}`
      );
      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

const locationsSlice = createSlice({
  name: "locations",
  initialState: {
    minimal: {
      status: "loading",
      error: null,
      data: [
        {
          locationsList: [],
          puttingLocationsList: [],
          pickingLocationsList: [],
          batchNumbersList: [],
          traceabilityNumbersList: [],
        },
      ],
    },
  } as LocationStore,
  reducers: {
    updateLocationList: (state, action) => {
      state.minimal.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocationsList.pending, (state) => {
        state.minimal.data[0].locationsList = [];
        state.minimal.status = "loading";
      })
      .addCase(fetchLocationsList.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
        state.minimal.data[0].locationsList = action.payload;
      })
      .addCase(fetchLocationsList.rejected, (state) => {
        state.minimal.status = "failed";
        state.minimal.data[0].locationsList = [];
      });
    builder
      .addCase(fetchPuttingLocationsList.pending, (state) => {
        state.minimal.data[0].puttingLocationsList = [];
        state.minimal.status = "loading";
      })
      .addCase(fetchPuttingLocationsList.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
        state.minimal.data[0].puttingLocationsList = action.payload;
      })
      .addCase(fetchPuttingLocationsList.rejected, (state) => {
        state.minimal.status = "failed";
        state.minimal.data[0].puttingLocationsList = [];
      });
    builder
      .addCase(fetchPickingLocationsList.pending, (state) => {
        state.minimal.data[0].pickingLocationsList = [];
        state.minimal.status = "loading";
      })
      .addCase(fetchPickingLocationsList.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
        state.minimal.data[0].pickingLocationsList = action.payload;
      })
      .addCase(fetchPickingLocationsList.rejected, (state) => {
        state.minimal.status = "failed";
        state.minimal.data[0].pickingLocationsList = [];
      });
    builder
      .addCase(fetchBatchNumber.pending, (state) => {
        state.minimal.data[0].batchNumbersList = [];
        state.minimal.status = "loading";
      })
      .addCase(fetchBatchNumber.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
        state.minimal.data[0].batchNumbersList = action.payload;
      })
      .addCase(fetchBatchNumber.rejected, (state) => {
        state.minimal.status = "failed";
        state.minimal.data[0].batchNumbersList = [];
      });
    builder
      .addCase(fetchTraceabilityNumber.pending, (state) => {
        state.minimal.data[0].traceabilityNumbersList = [];
        state.minimal.status = "loading";
      })
      .addCase(fetchTraceabilityNumber.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
        state.minimal.data[0].traceabilityNumbersList = action.payload;
      })
      .addCase(fetchTraceabilityNumber.rejected, (state) => {
        state.minimal.status = "failed";
        state.minimal.data[0].traceabilityNumbersList = [];
      });

    builder
      .addCase(createLocation.pending, (state) => {
        state.minimal.status = "loading";
      })
      .addCase(createLocation.fulfilled, (state) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
      })
      .addCase(createLocation.rejected, (state) => {
        state.minimal.status = "failed";
      });
    builder
      .addCase(deleteLocation.pending, (state) => {
        state.minimal.status = "loading";
      })
      .addCase(deleteLocation.fulfilled, (state) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
      })
      .addCase(deleteLocation.rejected, (state) => {
        state.minimal.status = "failed";
      });
  },
});
export const { updateLocationList } = locationsSlice.actions;
export default locationsSlice.reducer;
