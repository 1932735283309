import React, {PropsWithChildren, useEffect} from "react"
import {useSelector} from "react-redux"
import {RootState} from "@store"
import {useLocation, useNavigate} from "react-router-dom"
import {isAuthenticated} from "@utils/auth.utils"
import {logout} from "@slices/auth.slice"
import {useAppDispatch} from "@src/redux/hooks"

export default function SecurityContext({children}: PropsWithChildren<{ id?: string }>) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const location = useLocation()

    const authentication = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        if (!isAuthenticated(authentication)) {
            navigate('/login')
        } else {
            if (authentication.user.data && authentication.user.data.expiresAt && authentication.user.data.issuedAt) {
                if (authentication.user.data.expiresAt <= authentication.user.data.issuedAt) {
                    dispatch(logout())
                } else {
                    const logoutTime = (authentication.user.data.expiresAt - authentication.user.data.issuedAt) * 1000
                    setTimeout(() => dispatch(logout()), logoutTime)
                }
            }

            if(location.pathname === '/login') navigate('/')
        }
    }, [authentication])


    return (
        <div>
            {children}
        </div>
    )
}