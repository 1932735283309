import productionSlice from '@slices/production.slice';
import {combineReducers} from "@reduxjs/toolkit"
import dialogReducer from "@slices/dialog.slice"
import authReducer from "@slices/auth.slice"
import configReducer from "@slices/config.slice"
import itemsReducer from "@slices/items.slice"
import machinesReducer from "@slices/machines.slice"
import locationsReducer from "@slices/locations.slice"
import partnersReducer from "@slices/partners.slice"
import ordersReducer from "@slices/orders.slice"
import inventoryReducer from "@slices/inventory.slice"
import puttingReducers from "@slices/putting.slice"
import pickingReducers from "@slices/picking.slice"
import createOrderReducer from "../slices/createOrder.slice";
import returnOrderSlice from "../slices/return-order.slice";
import dashboardSlice from "../slices/dashboard.slice";
import qualityApprovalSlice from "../slices/qualityApproval.slice";
import downloadSlice from "../slices/download.slice";
const rootReducer = combineReducers({
  dialogs: dialogReducer,
  auth: authReducer,
  config: configReducer,
  items: itemsReducer,
  machines: machinesReducer,
  locations: locationsReducer,
  partners: partnersReducer,
  orders: ordersReducer,
  createOrder: createOrderReducer,
  inventory: inventoryReducer,
  puttings: puttingReducers,
  pickings: pickingReducers,
  production: productionSlice,
  returnOrder: returnOrderSlice,
  dashboard: dashboardSlice,
  qualityAprroval: qualityApprovalSlice,
  download: downloadSlice,
});

export default rootReducer
