import {DialogMetadataRef} from "@common/domain/common.model"
import {Dialog, Transition} from "@headlessui/react"
import {useAppDispatch, useAppSelector} from "@src/redux/hooks"

import {closeDialog, getDialog} from "@src/redux/slices/dialog.slice"
import {classNames} from "@utils/styles"
import React from "react"


const resolveSize = (size: DialogMetadataRef["size"]) => {
    switch (size) {
        case "xs":
            return {
                width: "20%",
                height: "20%"
            }
        case "sm":
            return {
                width: "25%",
                height: "25%"
            }
        case "md":
            return {
                width: "40%",
                height: "40%"
            }
        case "lg":
            return {
                width: "65%",
                height: "65%"
            }
        case "xl":
            return {
                width: "85%",
                height: "85%"
            }
        default:
            return {
                width: "40%",
                height: "40%"
            }
    }
}

const placementClasses = (placement: DialogMetadataRef["placement"],
                          variant: DialogMetadataRef["variant"]) => {
    switch (placement) {
        case "top-left":
            return variant === "drawer" ? `top-0 left-0` : `top-5 left-5`
        case "top-right":
            return variant === "drawer" ? `top-0 right-0` : `top-5 right-5`
        case "bottom-left":
            return variant === "drawer" ? `bottom-0 left-0` : `bottom-5 left-5`
        case "bottom-right":
            return variant === "drawer" ? `bottom-0 right-0` : `bottom-5 right-5`
        case "center":
            return "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        case "left":
            return variant === "drawer" ? `top-1/2 left-0 transform -translate-y-1/2` : `top-1/2 left-5 transform -translate-y-1/2`
        case "right":
            return variant === "drawer" ? `top-1/2 right-0 transform -translate-y-1/2` : `top-1/2 right-5 transform -translate-y-1/2`
        default:
            return "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"

    }
}

const resolveStyles = (variant: DialogMetadataRef["variant"],
                       size: DialogMetadataRef["size"],
                       scrollable: DialogMetadataRef["scrollable"],
                       modifiedStyles?: React.CSSProperties) => {
    const presets = resolveSize(size)
    const style = {
        ...modifiedStyles,
        ...presets,
        height: scrollable ? presets.height : undefined,
        maxHeight: scrollable ? presets.height : undefined
    }
    if (variant === "drawer") {
        return {
            ...style,
            height: "100%",
            maxHeight: "100vh"
        }
    }
    if (variant === "floating-drawer") {
        return {
            ...style,
            height: "100%",
            maxHeight: "96vh"
        }
    }
    return style
}

export default function DialogOverlay() {
    const dialogs = useAppSelector(state => state.dialogs)
    const dispatch = useAppDispatch()

    return (
        <>
            {dialogs.map(({
                              id,
                              size,
                              placement,
                              variant,
                              style,
                              className,
                              scrollable
                          }) => (
                <Transition
                    key={id}
                    show
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog
                        key={`dialog-${id}`}
                        open
                        onClose={() => dispatch(closeDialog(id!))}>
                        <div className="fixed inset-0 bg-black/30 z-999" aria-hidden="true"/>
                        <div className={classNames(
                            "fixed inset-0 flex",
                            variant === "drawer" ? "z-999 p-0" : "z-999 p-4"
                        )}>
                            <Dialog.Panel
                                key={`dialog-panel-${id}`}
                                as="div"
                                className={
                                    classNames(
                                        "fixed bg-white shadow-lg rounded-lg overflow-hidden p-3",
                                        placementClasses(placement, variant),
                                        variant === "drawer" ? "h-full rounded-none w-96" : "",
                                        variant === "floating-drawer" ? "h-full" : "",
                                        className ?? ""
                                    )
                                }
                                style={resolveStyles(variant, size, scrollable, style)}
                            >
                                <div
                                    className="overflow-y-auto h-full scrollbar-thumb-main-200 scrollbar-thin scrollbar-thumb-rounded-full">
                                    {getDialog(id!)}
                                </div>
                            </Dialog.Panel>
                        </div>
                    </Dialog>
                </Transition>
            ))}
        </>
    )
}
