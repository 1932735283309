import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApplicationResponse } from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import { puttingStore } from "@src/views/putting/domain/putting.model";

export const approveOrder = createAsyncThunk(
  "orders/approveOrder",
  async (data: any) => {
    try {
      const response = await api.put<ApplicationResponse<any[]>>(
        `${AppConstants.api}/orders/${data.orderId}/approve`,
        data.data
      );
      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

export const fetchRejectedOrdersList = createAsyncThunk(
  "orders/rejectedOrdersList",
  async (page: any) => {
    try {
      const response = await api.get<ApplicationResponse<any>>(
        `${AppConstants.api}/orders/declined?offset=${page.page}&pageSize=5&search=${page.query}`
        // http://localhost:5000/orders/list?type=INWARD
      );
      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

// export const disApproveOrder = createAsyncThunk(
//   "orders/disApproveOrder",
//   async (data: any) => {
//     try {
//       const response = await api.post<ApplicationResponse<any[]>>(
//         `${AppConstants.api}/orders/${data.orderId}/disapprove`,
//         data
//       );
//       return response.data.data || [];
//     } catch (error) {
//       return [];
//     }
//   }
// );

const qualityApprovalSlice = createSlice({
  name: "putting",
  initialState: {
    minimal: {
      status: "loading",
      error: null,
      data: [],
    },
  } as puttingStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(approveOrder.pending, (state) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(approveOrder.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(approveOrder.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });

    builder
      .addCase(fetchRejectedOrdersList.pending, (state) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchRejectedOrdersList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchRejectedOrdersList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });
    // builder
    //   .addCase(disApproveOrder.pending, (state) => {
    //     state.minimal = {
    //       status: "loading",
    //       data: [],
    //       error: null,
    //     };
    //   })
    //   .addCase(disApproveOrder.fulfilled, (state, action) => {
    //     state.minimal = {
    //       status: "idle",
    //       data: action.payload,
    //       error: null,
    //     };
    //   })
    //   .addCase(disApproveOrder.rejected, (state, action) => {
    //     state.minimal = {
    //       status: "failed",
    //       data: [],
    //       error: action.error,
    //     };
    //   });
  },
});
export default qualityApprovalSlice.reducer;
