import { createAsyncThunk, createSlice, Action } from "@reduxjs/toolkit";
import { ApplicationResponse } from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import { currentDate } from "@src/utils/dates";
import { INVOICENO } from "@src/utils/invoice.generate";
import { puttingStore } from "@src/views/putting/domain/putting.model";

export const fetchDestinationList = createAsyncThunk(
  "putting/fetchDestinations",
  async () => {
    return [
      {
        name: "A-01",
        status: "Empty",
        description: "A-01 Empty",
      },
      {
        name: "A-02",
        status: "Empty",
        description: "A-02 Empty",
      },
      {
        name: "A-03",
        status: "Half-empty",
        description: "A-03 Half-empty",
      },
      {
        name: "A-04",
        status: "Full",
        description: "A-04 Full",
      },
      {
        name: "A-05",
        status: "Full",
        description: "A-05 Full",
      },
    ];
  }
);

export const fetchReturnTraceabilityNumbers = createAsyncThunk(
  "return/fetchReturnTraceabilityNumbers",
  async (data: any) => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/orders/trace?id=${data.orderId}&batch=${data.batch}`
      );
      return response.data.data || [];
    } catch (err) {
      return [];
    }
  }
);

export const fetchReturnBatchNumbers = createAsyncThunk(
  "return/fetchReturnBatchNumbers",
  async (orderId: string) => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/orders/batch?id=${orderId}`
        // http://localhost:5000/orders/list?type=INWARD
      );
      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

export const createReturnOrder = createAsyncThunk(
  "return/createOrder",
  async (dataToSubmit: any) => {
    try {
      const response = await api.post<ApplicationResponse<any[]>>(
        `${AppConstants.api}/orders/return`,
        dataToSubmit
        // http://localhost:5000/orders/list?type=INWARD
      );
      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

export const fetchReturnLocations = createAsyncThunk(
  "return/locations",
  async (orderId: string) => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/orders/return/${orderId}/location`
      );
      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

const returnOrderSlice = createSlice({
  name: "putting",
  initialState: {
    minimal: {
      status: "loading",
      error: null,
      data: [
        {
          returnBatchNumberData: [],
          returnTraceabilityNumberData: [],
          returnData: [],
          returnLocationsData: [],
          data: [],
        },
      ],
    },
  } as puttingStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReturnBatchNumbers.pending, (state) => {
        state.minimal.status = "loading";
        state.minimal.data[0].returnBatchNumberData = [];
        state.minimal.error = null;
      })
      .addCase(fetchReturnBatchNumbers.fulfilled, (state, action) => {
        state.minimal.data[0].returnBatchNumberData = action.payload;
        state.minimal.status = "idle";
      })
      .addCase(fetchReturnBatchNumbers.rejected, (state, action) => {
        state.minimal.error = action.error;
        state.minimal.status = "failed";
        state.minimal.data[0].returnBatchNumberData = [];
      });
    builder
      .addCase(fetchReturnTraceabilityNumbers.pending, (state) => {
        state.minimal.status = "loading";
        state.minimal.data[0].returnTraceabilityNumberData = [];
        state.minimal.error = null;
      })
      .addCase(fetchReturnTraceabilityNumbers.fulfilled, (state, action) => {
        state.minimal.data[0].returnTraceabilityNumberData = action.payload;
        state.minimal.status = "idle";
        state.minimal.error = null;
      })
      .addCase(fetchReturnTraceabilityNumbers.rejected, (state, action) => {
        state.minimal.error = action.error;
        state.minimal.status = "failed";
        state.minimal.data[0].returnTraceabilityNumberData = [];
      });
    builder
      .addCase(fetchReturnLocations.pending, (state) => {
        state.minimal.status = "loading";
        state.minimal.data[0].returnLocationsData = [];
        state.minimal.error = null;
      })
      .addCase(fetchReturnLocations.fulfilled, (state, action) => {
        state.minimal.data[0].returnLocationsData = action.payload;
        state.minimal.status = "idle";
        state.minimal.error = null;
      })
      .addCase(fetchReturnLocations.rejected, (state, action) => {
        state.minimal.error = action.error;
        state.minimal.status = "failed";
        state.minimal.data[0].returnLocationsData = [];
      });

    builder
      .addCase(createReturnOrder.pending, (state) => {
        state.minimal.status = "loading";
        state.minimal.data[0].data = [];
        state.minimal.error = null;
      })
      .addCase(createReturnOrder.fulfilled, (state, action) => {
        state.minimal.data[0].data = action.payload;
        state.minimal.status = "idle";
        state.minimal.error = null;
      })
      .addCase(createReturnOrder.rejected, (state, action) => {
        state.minimal.error = action.error;
        state.minimal.status = "failed";
        state.minimal.data[0].data = [];
      });
  },
});

export default returnOrderSlice.reducer;
