import {format, parse} from "date-fns"
import { StandardTimestamp } from '@src/common/domain/common.model'

export const parseDate = (date: string) => parse(date, "d-MM-yyyy HH:mm:ss", new Date())

export const formatDate = (date: Date, dateFormat?: string) => format(date, dateFormat || "d MMM yyyy hh:mm aa")

export const convertToIST = (timestamp : StandardTimestamp) => {
    // Parse the input timestamp in the specified format
    // we pass timeStamp in this formet {time: "18-11-2023 08:31:22", format: "dd-MM-yyyy HH:mm:ss", timezone: "UTC"}
    const parts = timestamp?.time.split(/[- :]/)
    const utcDateTime = new Date( Date.UTC(
      +parts[2],
      +parts[1] - 1,
      +parts[0],
      +parts[3],
      +parts[4],
      +parts[5]
    )
  )
    // Convert to IST
    const istDateTime = new Date(utcDateTime.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }))
 
    return {
      dateTime: istDateTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }),
      time: istDateTime.toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' }),
      date: istDateTime.toISOString().split('T')[0], // "YYYY-MM-DD"
    }
  }

  export const currentDate = function () {
    const date = new Date();
    const day = String(date.getDay()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };