import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApplicationResponse } from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import { OrderStore } from "@views/orders/domain/orders.model";
import { toast } from "react-toastify";
export const createOrder = createAsyncThunk(
  "putting/createNewOrder",
  async (formData: object, { rejectWithValue }) => {
    try {
      const response = await api.post<any>(
        `${AppConstants.api}/orders/create`,
        formData
      );
      return response.data.data || [];
    } catch (err: any) {
      // console.error(err.message);
      return [];
    }
  }
);

const createOrderSlice = createSlice({
  name: "orders",
  initialState: {
    minimal: { status: "loading", error: null, data: [] },
  } as OrderStore,
  reducers: {
    updateOrdersList: (state, action) => {
      state.minimal.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state, action) => {
        state.minimal.status = "loading";
        state.minimal.data = [];
        state.minimal.error = null;
        toast("Order is being created.", {
          position: toast.POSITION.BOTTOM_RIGHT, // Or any other position
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false, // Optional property for draggable toasts
        });
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.data = action.payload;
        state.minimal.error = null;
        toast("Order has been fulfilled", {
          position: toast.POSITION.BOTTOM_RIGHT, // Or any other position
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false, // Optional property for draggable toasts
        });
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.minimal.status = "failed";
        state.minimal.data = [];
        state.minimal.error = null;
        toast("Order couldn't be fulfilled. Please try again later", {
          position: toast.POSITION.BOTTOM_RIGHT, // Or any other position
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false, // Optional property for draggable toasts
        });
      });
  },
});

export const { updateOrdersList } = createOrderSlice.actions;
export default createOrderSlice.reducer;
