export default function convertToForm(payload: any, keys: string[]) {
    const formData = new FormData()
    keys.forEach(key => {
        const value = payload[key]
        if (value !== null && value !== undefined) {
            if (key === 'file' || Array.isArray(value)) {
                if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        const subKeys = Object.keys(item)
                        subKeys.forEach(subKey => {
                            formData.append(`${key}[${index}].${subKey}`, item[subKey])
                        })
                    })
                } else {
                    formData.append(key, value)
                }
            } else if (typeof value === 'string') {
                formData.append(key, value)
            } else {
                formData.append(key, JSON.stringify(value))
            }
        }
    })

    return formData
}