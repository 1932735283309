import {ApplicationResponse, StateTrackedData} from "@common/domain/common.model";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "@common/services/api.service";
import AppConstants from "@utils/app.constants";
import {NewPartnerModelRef, PartnerStore} from "@views/partners/domain/partners.model";
import convertToForm from "@src/utils/payload-to-form-converter";

export const fetchPartnersList = createAsyncThunk<any[], void>(
    "partners/fetchPartners",
    async () => {

        const response = await api.get<ApplicationResponse<any[]>>(
          `${AppConstants.api}/partner/list`
        );

        return response.data.data || [];


        return [
            {
                id: "1",
                name: "Conroy Group",
                type: "CUSTOMER",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
                orders: [
                    {
                        id: "o1",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o2",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o3",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o4",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o5",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o6",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o7",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o8",
                        invoiceNo: "INV-36629"
                    }
                ]
            },
            {
                id: "2",
                name: "Turcotte Group",
                type: "VENDOR",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
                orders: [
                    {
                        id: "o1",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o2",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o3",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o4",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o5",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o6",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o7",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o8",
                        invoiceNo: "INV-36629"
                    }
                ]
            },
            {
                id: "3",
                name: "Bayer-Beier",
                type: "VENDOR",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
                orders: [
                    {
                        id: "o1",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o2",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o3",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o4",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o5",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o6",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o7",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o8",
                        invoiceNo: "INV-36629"
                    }
                ]
            },
            {
                id: "4",
                name: "Block Inc",
                type: "VENDOR",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
                orders: [
                    {
                        id: "o1",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o2",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o3",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o4",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o5",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o6",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o7",
                        invoiceNo: "INV-36629"
                    },
                    {
                        id: "o8",
                        invoiceNo: "INV-36629"
                    }
                ]
            }
        ]
    })

    export const uploadPartner = createAsyncThunk<void, {
        payload: NewPartnerModelRef
    }>("partner/upload",
        async (wrapper, thunkAPI) => {
            try {
                const url = `${AppConstants.api}/users/new`
                const {
                    payload
                } = wrapper
    
                const keys = Object.keys(payload) as Array<keyof NewPartnerModelRef>
                const formData = convertToForm(payload, keys)
    
                const response = await api.post<ApplicationResponse<void>>(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
    
                return response.data.data
            } catch (err: any) {
                if (!err.response) {
                    return thunkAPI.rejectWithValue(err.response.data)
                }
    
                return thunkAPI.rejectWithValue({
                    error: {
                        message: "Network error",
                        code: "NETWORK_ERROR",
                        attributes: {}
                    },
                    status: "error",
                    timestamp: new Date().toISOString()
                })
            }
        })
const partnersSlice = createSlice({
    name: "partners",
    initialState: {minimal: {status: "loading", error: null, data: []}} as PartnerStore,
    reducers: {
        updatePartnersList: (state,action)=>{
            state.minimal.data.push(action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPartnersList.pending, (state, action) => {
                state.minimal = {
                    status: "loading",
                    data: [],
                    error: null
                }
            })
            .addCase(fetchPartnersList.fulfilled, (state, action) => {
                state.minimal = {
                    status: "idle",
                    data: action.payload,
                    error: null
                }
            })
            .addCase(fetchPartnersList.rejected, (state, action) => {
                state.minimal = {
                    status: "idle",
                    data: [],
                    error: action.error
                }
            })

    }
})

export const {updatePartnersList} = partnersSlice.actions;
export default partnersSlice.reducer