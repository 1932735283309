import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {InventoryStore} from "@views/inventory/domain/inventory.model";

export const fetchInventory = createAsyncThunk<any[], void>(
    "inventory/fetch",
    async () => {

        // const response = await api.get<ApplicationResponse<any[]>>(`${AppConstants.api}/locations`)

        return [
            {
                item: "PA 66",
                quantity: "10",
                location: "Location 1",
                createdOn: "01 Jan 2023"
            },
            {
                item: "PF 88",
                quantity: "20",
                location: "Location 2",
                createdOn: "01 Jan 2023"
            },
        ]
    })


const inventorySlice = createSlice({
    name: "inventory",
    initialState: {minimal: {status: "loading", error: null, data: []}} as InventoryStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInventory.pending, (state, action) => {
                state.minimal = {
                    status: "loading",
                    data: [],
                    error: null
                }
            })
            .addCase(fetchInventory.fulfilled, (state, action) => {
                state.minimal = {
                    status: "idle",
                    data: action.payload,
                    error: null
                }
            })
            .addCase(fetchInventory.rejected, (state, action) => {
                state.minimal = {
                    status: "failed",
                    data: [],
                    error: action.error
                }
            })

    }
})

export default inventorySlice.reducer