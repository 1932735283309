import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApplicationResponse } from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import { currentDate } from "@src/utils/dates";
import { INVOICENO } from "@src/utils/invoice.generate";
import { showFailedToast } from "@src/utils/toast.utils";
import { pickingStore } from "@src/views/picking/modal/picking.model";
export const fetchPickingList = createAsyncThunk(
  "fetchPickingList",
  async function (data: any, { rejectWithValue }) {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/orders/list?type=OUTWARD&offset=${data.page}&pageSize=5&search=${data.query}`
      );

      if (response.data.status === "ERROR") {
        // Handle error response
        showFailedToast(`Error: ${response.data.error.message}`);
        return rejectWithValue(response.data);
      }

      return response.data.data || [];
    } catch (err) {
      return [];
    }
  }
);
const pickingSlice = createSlice({
  name: "picking",
  initialState: {
    minimal: { status: "loading", error: null, data: [] },
  } as pickingStore,
  reducers: {
    addToPickingList: (state, action) => {
      state.minimal.data = [
        {
          invoiceNo: INVOICENO(),
          destination: action.payload.destination,
          createdOn: currentDate(),
          status: "In Progress",
        },
        ...state.minimal.data,
      ];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPickingList.rejected, (state, action) => {
      state.minimal.error = action.error;
      state.minimal.status = "failed";
      state.minimal.data = [];
      // state.minimal.error = null;
    });
    builder.addCase(fetchPickingList.fulfilled, (state, action) => {
      state.minimal.status = "idle";
      state.minimal.data = action.payload;
    });
    builder.addCase(fetchPickingList.pending, (state, action) => {
      state.minimal.status = "loading";
      state.minimal.data = [];
      state.minimal.error = null;
    });
  },
});
export const { addToPickingList } = pickingSlice.actions;
export default pickingSlice.reducer;
