import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "@common/services/api.service";
import AppConstants from "@utils/app.constants";
import {
  ApplicationResponse,
  StateTrackedData,
} from "@common/domain/common.model";
import { ItemStore, NewItemModelRef } from "@views/items/domain/items.model";
import convertToForm from "@src/utils/payload-to-form-converter";
import { v4 as uuid } from "uuid";
import {
  BopCategoryId,
  RmCategoryId,
  bopData,
  rmData,
} from "@src/views/items/data/items.data";
const mockedList = [
  {
    id: "1",
    name: "PA16 55",
    type: "Best Of Product",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
    orders: [
      {
        id: "o1",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o2",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o3",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o4",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o5",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o6",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o7",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o8",
        invoiceNo: "YG8 Terminal End Assy",
      },
    ],
  },
  {
    id: "2",
    name: "MB15 99",
    type: "RAW MATERIAL",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
    orders: [
      {
        id: "o1",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o2",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o3",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o4",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o5",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o6",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o7",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o8",
        invoiceNo: "YG8 Terminal End Assy",
      },
    ],
  },
  {
    id: "3",
    name: "Nylon 66",
    type: "RAW MATERIALS",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
    orders: [
      {
        id: "o1",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o2",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o3",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o4",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o5",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o6",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o7",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o8",
        invoiceNo: "YG8 Terminal End Assy",
      },
    ],
  },
  {
    id: "4",
    name: "Nylon 81",
    type: "BEST OF PRODUCT",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent iaculis sagittis nisl a tempor. Nam scelerisque neque urna, in tristique elit tincidunt ac. Donec rhoncus, sapien interdum elementum ornare, libero sapien rhoncus velit, sit amet blandit urna enim a nunc.",
    orders: [
      {
        id: "o1",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o2",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o3",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o4",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o5",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o6",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o7",
        invoiceNo: "YG8 Terminal End Assy",
      },
      {
        id: "o8",
        invoiceNo: "YG8 Terminal End Assy",
      },
    ],
  },
];

export const deleteItem = createAsyncThunk(
  "items/delete",
  async (id: string) => {
    try {
      const response = await api.delete<ApplicationResponse<any[]>>(
        `${AppConstants.api}/item/delete?itemId=${id}`
      );
      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

export const fetchItemsList = createAsyncThunk(
  "items/fetchItems",
  async (arg: string) => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/item/list/${arg}`
      );

      return response.data.data || [];
    } catch (err) {
      return [];
    }
    // return mockedList;
    // if (arg === RmCategoryId) {
    //   return rmData;
    // }
    // if (arg === BopCategoryId) {
    //   return bopData;
    // }
  }
);
export const fetchItemsCategoryList = createAsyncThunk<any[], void>(
  "items/fetchItemsCategory",
  async () => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/item-category/list`
      );
      return response.data.data || [];
    } catch (err) {
      return [];
    }
    // return [
    //   { id: RmCategoryId, name: "Row Material", value: "rm" },
    //   { id: BopCategoryId, name: "Best of Product", value: "bop" },
    // ];
  }
);
export const uploadItems = createAsyncThunk<
  void,
  {
    payload: NewItemModelRef;
  }
>("uploadItems", async (wrapper, thunkAPI) => {
  try {
    const url = `${AppConstants.api}/item/create`;
    const { payload } = wrapper;

    const keys = Object.keys(payload) as Array<keyof NewItemModelRef>;
    const formData = convertToForm(payload, keys);

    const response = await api.post<ApplicationResponse<void>>(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      return thunkAPI.rejectWithValue(err.response.data);
    }

    return thunkAPI.rejectWithValue({
      error: {
        message: "Network error",
        code: "NETWORK_ERROR",
        attributes: {},
      },
      status: "error",
      timestamp: new Date().toISOString(),
    });
  }
});

export const createItem = createAsyncThunk(
  "items/create",
  async (data: any) => {
    try {
      const response = await api.post<ApplicationResponse<any[]>>(
        `${AppConstants.api}/item/create`,
        data
      );
      return response.data.data || [];
    } catch (err) {
      return [];
    }
  }
);

const itemsSlice = createSlice({
  name: "items",
  initialState: {
    minimal: {
      status: "loading",
      error: null,
      data: [
        {
          itemsList: [],
          itemsCategoryList: [],
          batchNumberList: [],
        },
      ],
    },
  } as ItemStore,
  reducers: {
    updateItemsList: (state, action) => {
      state.minimal.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchItemsList.pending, (state) => {
        state.minimal.status = "loading";
        state.minimal.data[0].itemsList = [];
      })
      .addCase(fetchItemsList.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
        state.minimal.data[0].itemsList = action.payload;
      })
      .addCase(fetchItemsList.rejected, (state) => {
        state.minimal.status = "failed";
        state.minimal.data[0].itemsList = [];
      });

    builder
      .addCase(fetchItemsCategoryList.pending, (state) => {
        state.minimal.status = "loading";
      })
      .addCase(fetchItemsCategoryList.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
        state.minimal.data[0].itemsCategoryList = action.payload;
      })
      .addCase(fetchItemsCategoryList.rejected, (state) => {
        state.minimal.status = "failed";
      });
    builder
      .addCase(createItem.pending, (state) => {
        state.minimal.status = "loading";
      })
      .addCase(createItem.fulfilled, (state) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
        // state.minimal.data[0].itemsCategoryList = action.payload;
      })
      .addCase(createItem.rejected, (state) => {
        state.minimal.status = "failed";
      });
    builder
      .addCase(deleteItem.pending, (state) => {
        state.minimal.status = "loading";
      })
      .addCase(deleteItem.fulfilled, (state) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
        // state.minimal.data[0].itemsCategoryList = action.payload;
      })
      .addCase(deleteItem.rejected, (state) => {
        state.minimal.status = "failed";
      });
  },
});
export const { updateItemsList } = itemsSlice.actions;
export default itemsSlice.reducer;
