import { MachineStore } from "@views/machines/domain/machine.model";
import AppConstants from "@utils/app.constants";
import {
  ApplicationResponse,
  StateTrackedData,
} from "@common/domain/common.model";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "@common/services/api.service";
import { v4 as uuid } from "uuid";
import { showFailedToast } from "@src/utils/toast.utils";

export const fetchMachinesList = createAsyncThunk(
  "machines/fetchMachines",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/machine/list`
        // http://localhost:5000/orders/list?type=INWARD
      );

      if (response.data.status === "ERROR") {
        // Handle error response
        showFailedToast(`Error: ${response.data.error.message}`);
        return rejectWithValue(response.data);
      }

      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

export const createMachine = createAsyncThunk(
  "machines/create",
  async (data: any) => {
    try {
      const response = await api.post<ApplicationResponse<any[]>>(
        `${AppConstants.api}/machine/list`,
        data
      );
      return response.data.data || [];
    } catch (err) {
      return [];
    }
  }
);

export const deleteMachine = createAsyncThunk(
  "machines/delete",
  async (id: string) => {
    try {
      const response = await api.delete<ApplicationResponse<any[]>>(
        `${AppConstants.api}/machine/delete?machineId=${id}`
      );
      return response.data.data || [];
    } catch (err) {
      return [];
    }
  }
);

const machinesSlice = createSlice({
  name: "machines",
  initialState: {
    minimal: { status: "loading", error: null, data: [] },
  } as MachineStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachinesList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchMachinesList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchMachinesList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });
    builder
      .addCase(createMachine.pending, (state) => {
        state.minimal.status = "loading";
      })
      .addCase(createMachine.fulfilled, (state) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
      })
      .addCase(createMachine.rejected, (state) => {
        state.minimal.status = "failed";
      });
    builder
      .addCase(deleteMachine.pending, (state) => {
        state.minimal.status = "loading";
      })
      .addCase(deleteMachine.fulfilled, (state) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
      })
      .addCase(deleteMachine.rejected, (state) => {
        state.minimal.status = "failed";
      });
  },
});

export default machinesSlice.reducer;
