import { createAsyncThunk, createSlice, Action } from "@reduxjs/toolkit";
import { ApplicationResponse } from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import { currentDate } from "@src/utils/dates";
import { INVOICENO } from "@src/utils/invoice.generate";
import { showFailedToast } from "@src/utils/toast.utils";
import { puttingStore } from "@src/views/putting/domain/putting.model";

export const fetchDestinationList = createAsyncThunk(
  "putting/fetchDestinations",
  async () => {
    return [
      {
        name: "A-01",
        status: "Empty",
        description: "A-01 Empty",
      },
      {
        name: "A-02",
        status: "Empty",
        description: "A-02 Empty",
      },
      {
        name: "A-03",
        status: "Half-empty",
        description: "A-03 Half-empty",
      },
      {
        name: "A-04",
        status: "Full",
        description: "A-04 Full",
      },
      {
        name: "A-05",
        status: "Full",
        description: "A-05 Full",
      },
    ];
  }
);

export const searchPuttingItems = createAsyncThunk(
  "puttings/searchPuttingItem",
  async (query: string) => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/orders/search?value=${query}&type=INWARD&offset=0&pageSize=5`
        // http://localhost:5000/orders/list?type=INWARD
      );
      return response.data.data || [];
    } catch (err) {
      return [];
    }
  }
);

export const fetchPuttingList = createAsyncThunk(
  "puttings/fetchPuttingList",
  async (page: any, { rejectWithValue }) => {
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/orders/list?type=INWARD&offset=${page.page}&pageSize=5&search=${page.query}`
      );

      if (response.data.status === "ERROR") {
        // Handle error response
        showFailedToast(`Error: ${response.data.error.message}`);
        return rejectWithValue(response.data);
      }
      return response.data.data || [];
    } catch (error) {
      return [];
    }
    // return [
    //   {
    //     invoiceNo: INVOICENO(),
    //     // itemCategory: "Raw materil",
    //     item: "Nylon 66 - PA66 GF 30%",
    //     // itemCode: "PA66 GF 30%",
    //     source: "Minda something",
    //     createdOn: "29-02-2024",
    //     status: "COMPLETED",
    //   },
    //   {
    //     invoiceNo: INVOICENO(),
    //     // itemCategory: "Raw materil",
    //     item: "Nylon 66 - PA66 GF 33%",
    //     // itemCode: "PA66 GF 30%",
    //     source: "Another Minda something",
    //     createdOn: "29-02-2024",
    //     status: "In Progress",
    //   },
    //   {
    //     invoiceNo: INVOICENO(),
    //     // itemCategory: "Raw materil",
    //     item: "Nylon 66 - PA66 GF 33%",
    //     // itemCode: "PA66 GF 30%",
    //     source: "Another Minda something",
    //     createdOn: "29-02-2024",
    //     status: "In Progress",
    //   },
    //   {
    //     invoiceNo: INVOICENO(),
    //     // itemCategory: "Raw materil",
    //     item: "Nylon 66 - PA66 GF 33%",
    //     // itemCode: "PA66 GF 30%",
    //     source: "Another Minda something",
    //     createdOn: "29-02-2024",
    //     status: "In Progress",
    //   },
    //   {
    //     invoiceNo: INVOICENO(),
    //     // itemCategory: "Raw materil",
    //     item: "Nylon 66 - PA66 GF 33%",
    //     // itemCode: "PA66 GF 30%",
    //     source: "Another Minda something",
    //     createdOn: "29-02-2024",
    //     status: "In Progress",
    //   },
    // ];
  }
);

const puttingSlice = createSlice({
  name: "putting",
  initialState: {
    minimal: {
      status: "loading",
      error: null,
      data: [],
    },
  } as puttingStore,
  reducers: {
    addToPuttingList: (state, action) => {
      state.minimal.data = [
        {
          invoiceNo: INVOICENO(),
          // itemCategory: action.payload.itemCategory,
          item: action.payload.item,
          // itemCode: ,
          source: action.payload.source,
          createdOn: currentDate(),
          status: "INCOMPLETE",
        },
        ...state.minimal.data,
      ];
    },
    updatePuttingList: (state, action) => {
      state.minimal.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDestinationList.pending, (state) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchDestinationList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchDestinationList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });

    builder
      .addCase(fetchPuttingList.pending, (state) => {
        state.minimal.status = "loading";
        state.minimal.data = [];
        state.minimal.error = null;
      })
      .addCase(fetchPuttingList.fulfilled, (state, action) => {
        state.minimal.data = action.payload;
        state.minimal.status = "idle";
      })
      .addCase(fetchPuttingList.rejected, (state, action) => {
        state.minimal.error = action.error;
        state.minimal.status = "failed";
        state.minimal.data = [];
      });
    builder
      .addCase(searchPuttingItems.pending, (state) => {
        state.minimal.status = "loading";
        state.minimal.data = [];
        state.minimal.error = null;
      })
      .addCase(searchPuttingItems.fulfilled, (state, action) => {
        state.minimal.data = action.payload;
        state.minimal.status = "idle";
        state.minimal.error = null;
      })
      .addCase(searchPuttingItems.rejected, (state, action) => {
        state.minimal.error = action.error;
        state.minimal.status = "failed";
        state.minimal.data = [];
      });
  },
});

export const { updatePuttingList, addToPuttingList } = puttingSlice.actions;
export default puttingSlice.reducer;
