import {startLoading, stopLoading} from "@slices/loader.slice"
import {store} from "@store"
import axios from "axios"
import {ApplicationResponse} from "../domain/common.model"
import {showSuccessToast} from "@utils/toast.utils"

const api = axios.create({})


api.interceptors.request.use(
    (config) => {
        store.dispatch(startLoading())
        return config
    },
    (error) => {
        store.dispatch(stopLoading())
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    (response) => {
        store.dispatch(stopLoading())
        const data = response.data as ApplicationResponse<any>
        if (data.status === 'RESOURCE_CREATED' || data.status === 'RESOURCE_UPDATED' || data.status === 'RESOURCE_DELETED') {
            showSuccessToast(data.message)
            window.location.reload();
        }
        return response


    },
    (error) => {
        store.dispatch(stopLoading())
        return Promise.reject(error)
    }
)

export const setupAxiosInterceptors = (store: any) => {
    api.interceptors.request.use(
        (config) => {
            const state = store.getState();
            const token = state.auth.token.data; // Assuming this is where your token is stored

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

export default api