import AppConstants from "@utils/app.constants";
import {
  ApplicationResponse,
  StateTrackedData,
} from "@common/domain/common.model";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "@common/services/api.service";
import {
  ProductionStore,
  productionTableData,
} from "@src/views/production/domain/prodution.model";
import { showFailedToast } from "@src/utils/toast.utils";

export const fetchProductionList = createAsyncThunk(
  "productions/fetchProductions",
  async (param: any, { rejectWithValue }) => {
    console.log(param);
    try {
      const response = await api.get<ApplicationResponse<any[]>>(
        `${AppConstants.api}/fg-goods/list?page_size=5&offset=${
          param.page
        }&start_date=${
          param?.startDate ? param.startDate + "T00:00:00" : ""
        }&end_date=${
          param?.endDate ? param.endDate + "T00:00:00" : ""
        }&search=${param?.query ? param.query : ""}`
      );

      if (response.data.status === "ERROR") {
        // Handle error response
        showFailedToast(`Error: ${response.data.error.message}`);
        return rejectWithValue(response.data);
      }
      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

const productionSlice = createSlice({
  name: "productions",
  initialState: {
    minimal: { status: "loading", error: null, data: [] },
  } as ProductionStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductionList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchProductionList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchProductionList.rejected, (state, action) => {
        state.minimal = {
          status: "idle",
          data: [],
          error: action.error,
        };
      });
  },
});

export default productionSlice.reducer;
